import React, { useEffect, useState } from 'react';
import {
    Row,
    Col,
    Spin,
    Form,
    Input,
    Button,
    notification,
    Modal as ModalAntd,
    Tooltip,
    Checkbox,
    Select,
} from 'antd';
import { getAccessTokenApi } from '../../../../../api/auth';
import { getSupplierApi, editSupplierApi } from '../../../../../api/supplier';
import { logout } from '../../../../../api/auth';
import { getCompanyIdApi } from '../../../../../api/company';
import {
    ExclamationCircleOutlined,
    QuestionCircleOutlined,
} from '@ant-design/icons';
import { getUsersApi } from '../../../../../api/users';

const EditSupplierForm = ({
    setModalIsVisible,
    setReloadSuppliers,
    idSupplier,
}) => {
    const [loadingForm, setLoadingForm] = useState(true);
    const [form] = Form.useForm();
    const [editUserForm, setEditUserForm] = useState([]);
    const companyId = getCompanyIdApi();
    const token = getAccessTokenApi();
    const { confirm } = ModalAntd;
    const [financiales, setFinanciales] = useState([]);

    useEffect(() => {
        if (loadingForm) {
            const filters = `?role=${'Financial'}`;
            getUsersApi(token, filters)
                .then((response) => {
                    if (response) {
                        let supp = response.result.map((f) => ({
                            value: f.id,
                            label: f.userName,
                        }));
                        setFinanciales(supp);
                    }
                    setLoadingForm(false);
                })
                .catch((err) => {});
        }
    }, [token, loadingForm]);

    useEffect(() => {
        getSupplierApi(token, idSupplier).then((response) => {
            console.log(response);
            setEditUserForm([
                {
                    name: ['name'],
                    value: response.result.name,
                },
                {
                    name: ['supplierNum'],
                    value: response.result.supplierNum,
                },
                {
                    name: ['supplierId'],
                    value: response.result.supplierId,
                },
                {
                    name: ['active'],
                    value: response.result.active,
                },
                {
                    name: ['address'],
                    value: response.result.address,
                },
                {
                    name: ['city'],
                    value: response.result.city,
                },
                {
                    name: ['state'],
                    value: response.result.state,
                },
                {
                    name: ['zipCode'],
                    value: response.result.zipCode,
                },
                {
                    name: ['country'],
                    value: response.result.country,
                },
                {
                    name: ['emailAddress'],
                    value: response.result.emailAddress,
                },
                {
                    name: ['contactName'],
                    value: response.result.contactName,
                },
                {
                    name: ['fiscalID'],
                    value: response.result.fiscalID,
                },
                {
                    name: ['phoneNumber'],
                    value: response.result.phoneNumber,
                },
                {
                    name: ['financialId'],
                    value: response.result.financialId,
                },
            ]);
        });
        // return () => {
        //     cleanup
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idSupplier]);

    const handleEditSupplier = (values) => {
        confirm({
            title: 'Editar Proveedor',
            icon: <ExclamationCircleOutlined />,
            content: '¿Estás seguro que deseas guardar datos modificados?',
            okText: 'Si',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                editSupplierFunction(values);
            },
            onCancel() {},
        });
    };

    const editSupplierFunction = async (values) => {
        setLoadingForm(true);
        let data = values;
        data['parentSupplierId'] = '';
        data['level'] = 0;
        data['company'] = companyId;
        let response = null;
        try {
            response = await editSupplierApi(token, data, idSupplier);
        } catch (error) {
        } finally {
            setLoadingForm(false);
        }
        if (response === undefined) {
            notification['error']({
                message: 'Ocurrió un error, Inténtelo más tarde',
            });
            return false;
        }
        if (response.statusCode === 401) {
            notification['error']({
                message: 'Usuario no autorizado.',
            });
            setTimeout(() => {
                logout();
                window.location.reload();
            }, 1500);
            return false;
        }
        if (response.statusCode === 200) {
            notification['success']({
                message: 'Proveedor editado con éxito',
            });
            setModalIsVisible(false);
        } else {
            let messageError = 'Ocurrió un error, Inténtelo otra vez';
            if (response.statusCode === 409) {
                messageError = response.description;
            }
            if (response.statusCode === 400) {
                messageError = response.description;
            }
            notification['error']({
                message: messageError,
            });
        }
        setReloadSuppliers(true);

        setLoadingForm(false);
    };
    return (
        <Spin spinning={loadingForm} tip="Cargando...">
            <Form
                name="add-promo"
                layout="vertical"
                form={form}
                onFinish={handleEditSupplier}
                fields={editUserForm}
                onFieldsChange={(changedFields, allFields) => {
                    setEditUserForm(allFields);
                }}
            >
                <div>
                    <Row xs={24} className="table-company">
                        <Col xs={20}>
                            <Form.Item
                                label="Nombre de Proveedor:"
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor ingrese un nombre',
                                    },
                                ]}
                            >
                                <Input placeholder="Ingresa tu información" />
                            </Form.Item>

                            <Form.Item
                                label="Número de proveedor:"
                                name="supplierNum"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor ingrese un número',
                                    },
                                ]}
                            >
                                <Input placeholder="Ingresa tu información" />
                            </Form.Item>
                            <Form.Item
                                label="Proveedor estatus:"
                                name="active"
                                valuePropName="checked"
                            >
                                <Checkbox>Activo</Checkbox>
                            </Form.Item>
                            <Form.Item
                                label="Id de proveedor:"
                                name="supplierId"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor ingrese un Id',
                                    },
                                ]}
                                hidden
                            >
                                <Input placeholder="Ingresa tu información" />
                            </Form.Item>
                            <Form.Item label="Estatus:" name="active" hidden>
                                <Checkbox checked />
                            </Form.Item>
                            <Form.Item
                                label="Dirección:"
                                name="address"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Por favor ingrese una dirección',
                                    },
                                ]}
                            >
                                <Input placeholder="Ingresa tu información" />
                            </Form.Item>
                            <Form.Item
                                label="Ciudad:"
                                name="city"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor ingrese una ciudad',
                                    },
                                ]}
                            >
                                <Input placeholder="Ingresa tu información" />
                            </Form.Item>
                            <Form.Item
                                label="Estado:"
                                name="state"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor ingrese un estado',
                                    },
                                ]}
                            >
                                <Input placeholder="Ingresa tu información" />
                            </Form.Item>
                            <Form.Item
                                label="CP:"
                                name="zipCode"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Por favor ingrese un código postal',
                                    },
                                ]}
                            >
                                <Input placeholder="Ingresa tu información" />
                            </Form.Item>
                            <Form.Item
                                label="País:"
                                name="country"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor ingrese un país',
                                    },
                                ]}
                            >
                                <Input placeholder="Ingresa tu información" />
                            </Form.Item>
                            <Form.Item
                                label="Correo Electrónico:"
                                name="emailAddress"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor ingrese un email',
                                    },
                                ]}
                            >
                                <Input placeholder="Ingresa tu información" />
                            </Form.Item>
                            <Form.Item
                                label="Nombre de contacto:"
                                name="contactName"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor ingrese un nombre',
                                    },
                                ]}
                            >
                                <Input placeholder="Ingresa tu información" />
                            </Form.Item>
                            <Form.Item
                                label="RFC:"
                                name="fiscalID"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor ingrese un RFC',
                                    },
                                ]}
                            >
                                <Input placeholder="Ingresa tu información" />
                            </Form.Item>
                            <Form.Item
                                label={
                                    <span>
                                        Teléfono:&nbsp;
                                        <Tooltip title="El número telefónico debe contener 10 dígitos">
                                            <QuestionCircleOutlined />
                                        </Tooltip>
                                    </span>
                                }
                                name="phoneNumber"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Por favor ingresa número telefónico',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(rule, value) {
                                            if (
                                                !value ||
                                                value.toString().length === 10
                                            ) {
                                                return Promise.resolve();
                                            }

                                            return Promise.reject(
                                                'Por favor ingresa número telefónico a 10 dígitos'
                                            );
                                        },
                                    }),
                                ]}
                            >
                                <Input placeholder="Ingresa tu información" />
                            </Form.Item>
                            {financiales && (
                                <Form.Item
                                    label="Financiero:"
                                    name="financialId"
                                    rules={[
                                        {
                                            message:
                                                'Por favor seleccione un financiero',
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder="Selecciona una opción"
                                        allowClear={true}
                                    >
                                        {financiales.map((f) => (
                                            <Select.Option
                                                key={f.value}
                                                value={f.value}
                                            >
                                                {f.label}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            )}

                            <Row>
                                <Col span={24}>
                                    <Form.Item>
                                        <Button
                                            htmlType="submit"
                                            className="btn-enviar"
                                            block
                                        >
                                            Guardar
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </Form>
        </Spin>
    );
};

export default EditSupplierForm;
