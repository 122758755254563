import React from 'react';

import { Layout, Row, Col, Typography } from 'antd';
import LoginForm from '../../components/General/LoginForm';
import LogoPlanB from '../../assets/img/png/logo_planb.png';
import LogoEmpresa from '../../assets/img/png/logo_empresa.png';

// import InstallPWA from '../../components/General/InstallPWA';

import './Login.scss';

export default function Login() {
    const { Content } = Layout;
    const { Paragraph, Title } = Typography;

    return (
        <Layout className="login">
            <Content className="login__content">
                <div className="login__content-banner animate__animated  animate__slideInDown">
                    <Row className="login-container">
                        <Col span={24}>
                            <img
                                src={LogoEmpresa}
                                alt="logo_empresa"
                                style={{ width: 100 }}
                            />
                            <Title level={3} style={{ marginTop: 20 }}>
                                Bienvenido
                            </Title>
                        </Col>
                    </Row>
                    <LoginForm />
                    <Row className="login-container">
                        <Col>
                            <Paragraph>Desarrollado por:</Paragraph>
                            <img
                                src={LogoPlanB}
                                alt="logo_planB"
                                style={{ width: 100 }}
                            />
                        </Col>
                    </Row>
                </div>

                {/* <InstallPWA login /> */}
            </Content>
        </Layout>
    );
}
