import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Layout, Menu, Row, Space, Typography, notification } from 'antd';
import {
    HomeOutlined,
    PieChartOutlined,
    FileSearchOutlined,
    PhoneOutlined,
    MailOutlined,
    SnippetsOutlined,
    SettingOutlined,
    UnorderedListOutlined,
    UserOutlined,
    TeamOutlined,
} from '@ant-design/icons';
import {
    getContactBySectionApi,
    getSectionsContactsApi,
} from '../../../api/sections';
import { useLocation } from 'react-router-dom';
import { getAccessTokenApi, logout } from '../../../api/auth';

import './MenuSider.scss';
import {
    ROL_ADMIN,
    ROL_MANAGER,
    ROL_OPERACIONES,
    ROL_SUPPLIER,
    ROL_FINANCIAL,
} from '../../../utils/constants';
import { formatPhoneNumber } from '../../../utils/general';

const MenuSider = ({ menuCollapsed, roles = [] }) => {
    const { Sider } = Layout;
    const { Paragraph, Text } = Typography;
    const token = getAccessTokenApi();
    const location = useLocation();
    const [nameContact, setNameContact] = useState('');
    const [phoneContact, setPhoneContact] = useState('');
    const [emailContact, setEmailContact] = useState('');

    useEffect(() => {
        const role = 'Financial';
        getContactBySectionApi(token, role).then((response) => {
            if (response) {
                if (response.statusCode === 401) {
                    notification['error']({
                        message: 'Usuario no autorizado',
                    });

                    setTimeout(() => {
                        logout();
                        window.location.reload();
                    }, 1500);
                    return;
                }
                if (response.statusCode === 200) {
                    setNameContact(response.result.name);
                    setPhoneContact(response.result.phoneNumber);
                    setEmailContact(response.result.email);
                }
            }
        });
    }, [token]);

    let selectedKey = location.pathname;
    let splitted = location.pathname.split('/');

    if (splitted.length > 2) {
        selectedKey = '/' + splitted[1];
    }
    
    return (
        <Sider
            className="admin-sider"
            collapsed={menuCollapsed}
            collapsible
            breakpoint="md"
            collapsedWidth="0"
            width={220}
        >
            <Menu
                theme="dark"
                mode="inline"
                defaultSelectedKeys={[selectedKey]}
                selectedKeys={selectedKey}
            >
                {[
                    ROL_MANAGER,
                    ROL_OPERACIONES,
                    ROL_SUPPLIER,
                    ROL_FINANCIAL,
                ].some((r) => roles.includes(r)) && (
                    <Menu.Item key="/">
                        <Link to="/">
                            <HomeOutlined />
                            <span className="nav-text">Inicio</span>
                        </Link>
                    </Menu.Item>
                )}
                {[ROL_MANAGER, ROL_FINANCIAL].some((r) =>
                    roles?.includes(r)
                )  && (
                    <Menu.Item key="/proveedores">
                        <Link to="/proveedores">
                            <UserOutlined />
                            <span className="nav-text">Proveedores</span>
                        </Link>
                    </Menu.Item>
                )}

                {[
                    ROL_MANAGER,
                    ROL_OPERACIONES,
                    ROL_SUPPLIER,
                    ROL_FINANCIAL,
                ].some((r) => roles.includes(r)) && (
                    <Menu.Item key="/facturacion">
                        <Link to="/facturacion">
                            <PieChartOutlined />
                            <span className="nav-text">Facturación</span>
                        </Link>
                    </Menu.Item>
                )}
                {[
                    ROL_MANAGER,
                    ROL_OPERACIONES,
                    ROL_SUPPLIER,
                    ROL_FINANCIAL,
                ].some((r) => roles.includes(r)) && (
                    <Menu.Item key="/pagos">
                        <Link to="/pagos">
                            <SnippetsOutlined />
                            <span className="nav-text">
                                Complemento de Pago
                            </span>
                        </Link>
                    </Menu.Item>
                )}
                {[ROL_MANAGER, ROL_OPERACIONES, ROL_SUPPLIER, ROL_FINANCIAL].some((r) =>
                    roles.includes(r)
                ) && (
                    <Menu.Item key="/aclaraciones">
                        <Link to="/aclaraciones">
                            <FileSearchOutlined />
                            <span className="nav-text">Aclaraciones</span>
                        </Link>
                    </Menu.Item>
                )}

                {roles.includes(ROL_ADMIN) && (
                    <Menu.Item key="/configuracion">
                        <Link to="/configuracion">
                            <SettingOutlined />
                            <span className="nav-text">Configuración</span>
                        </Link>
                    </Menu.Item>
                )}
                {[ROL_ADMIN, ROL_OPERACIONES].some((r) =>
                    roles.includes(r)
                ) && (
                    <Menu.Item key="/catalogo">
                        <Link to="/catalogo">
                            <UnorderedListOutlined />
                            <span className="nav-text">Catálogo</span>
                        </Link>
                    </Menu.Item>
                )}
            </Menu>
            {[ROL_SUPPLIER].some((r) => roles.includes(r)) && (
                <>
                    {(location.pathname === '/facturacion' ||
                        location.pathname === '/pagos') && (
                        <div className="menu-extra">
                            {phoneContact !== '' && (
                                <div className="menu-info">
                                    <Row style={{ justifyContent: 'center' }}>
                                        <Space size={5} align="baseline">
                                            <PhoneOutlined />
                                            <Paragraph>
                                                Tel. {''}
                                                {formatPhoneNumber(phoneContact)}
                                            </Paragraph>
                                        </Space>
                                    </Row>
                                </div>
                            )}
                            <div className="menu-info">
                                {nameContact !== '' && (
                                    <Row style={{ justifyContent: 'center' }}>
                                        <div style={{ width: 200 }}>
                                            <Space size={5} align="baseline">
                                                <MailOutlined />
                                                <Paragraph>
                                                    Asesor {''}
                                                    {nameContact}
                                                </Paragraph>
                                            </Space>
                                        </div>
                                    </Row>
                                )}
                                <Row style={{ justifyContent: 'center' }}>
                                    <div style={{ width: 200 }}>
                                        <Paragraph>{emailContact}</Paragraph>
                                    </div>
                                </Row>
                            </div>
                        </div>
                    )}
                </>
            )}
        </Sider>
    );
};

export default withRouter(MenuSider);
