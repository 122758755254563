import React, { useEffect, useState } from 'react';
import {
    Button,
    Dropdown,
    Menu,
    Avatar,
    Badge,
    Image,
    notification,
} from 'antd';
import {
    PoweroffOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    DownOutlined,
    UserOutlined,
    NotificationFilled,
    CaretRightOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { getAccessTokenApi, logout } from '../../../api/auth';
import logoPlanB from '../../../assets/img/png/logo_planb.png';
import useUser from '../../../hooks/useUser';
import { logOutError, messageError } from '../../../utils/feedbackMessages';

import './MenuTop.scss';
import { getUserAlertsApi, postUserAlertsApi } from '../../../api/alerts';
import { ROL_FINANCIAL, ROL_SUPPLIER } from '../../../utils/constants';

const MenuTop = (props) => {
    const { menuCollapsed, setMenuCollapsed } = props;
    const { person } = useUser();
    const [profilePicture, setProfilePicture] = useState(null);
    const token = getAccessTokenApi();
    const [alerts, setAlerts] = useState([]);
    const [numberAlerts, setNumberAlerts] = useState([]);
    const [reloadAlerts, setReloadAlerts] = useState(true);
    useEffect(() => {
        if (person) {
            const data = person.profilePicture;
            if (data !== '') {
                const imageProfile = `data:image/jpeg;base64, ${data}`;
                setProfilePicture(imageProfile);
            }
        } else {
            logOutError();
        }
    }, [person]);
    useEffect(() => {
        if (reloadAlerts) {
            getUserAlertsApi(token).then((response) => {
                if (response === undefined) {
                    const msg = messageError();

                    notification['error']({
                        message: msg,
                    });
                    return;
                }
                if (response?.statusCode === 401) {
                    notification['error']({
                        message: 'Usuario no autorizado.',
                    });
                    setTimeout(() => {
                        logout();
                        window.location.reload();
                    }, 1000);
                    return;
                } else if (response?.statusCode === 200) {
                    let numeroDeAlertas = 0;
                    response.result.forEach((element, index) => {
                        element['key'] = index;
                        if (element.isSeen === false) {
                            numeroDeAlertas++;
                        }
                    });
                    setAlerts(response.result);
                    setNumberAlerts(numeroDeAlertas);
                }
            });
        }
    }, [token, reloadAlerts]);
    const menu = (
        <Menu>
            <Menu.Item key="pictureUser">
                <Image
                    src={profilePicture ? profilePicture : logoPlanB}
                    style={{ width: 150 }}
                />
            </Menu.Item>
            <Menu.Item key="/perfil">
                <Link to="/perfil">
                    <UserOutlined />
                    <span className="nav-text">Perfil</span>
                </Link>
            </Menu.Item>
            <Menu.Item
                key="nameUser"
                danger
                icon={<PoweroffOutlined />}
                onClick={() => logoutUser()}
            >
                Salir
            </Menu.Item>
        </Menu>
    );
    function handleMenuClick(e) {}

    const menuNotifications = (
        <Menu
            onClick={handleMenuClick}
            className={alerts.length > 5 && 'active-scroll'}
        >
            {alerts.length === 0 && (
                <Menu.Item>No hay alertas activas</Menu.Item>
            )}
            {alerts.map((alert, index) => (
                <Menu.Item
                    key={index}
                    icon={
                        <CaretRightOutlined
                            style={{
                                color:
                                    alert.pinColor === 'rojo'
                                        ? 'red'
                                        : alert.pinColor === 'verde'
                                        ? 'green'
                                        : 'orange',
                            }}
                        />
                    }
                >
                    <span style={{ textOverflow: 'ellipsis', maxWidth: 300 }}>
                        {alert.description
                            ? alert.description.substring(0, 30) +
                              (alert.description.length > 30 ? '...' : '')
                            : ''}
                    </span>
                </Menu.Item>
            ))}
        </Menu>
    );
    const logoutUser = () => {
        logout();
        window.location.reload();
    };
    const showNotifications = async () => {
        if (alerts.length !== 0) {
            const response = await postUserAlertsApi(token);
            if (response === undefined) {
                notification['error']({
                    message: 'Ocurrió un error, Inténtelo más tarde',
                });
                return false;
            }
            if (response.statusCode === 401) {
                notification['error']({
                    message: 'Usuario no autorizado.',
                });
                setTimeout(() => {
                    logout();
                    window.location.reload();
                }, 1500);
                return false;
            }
            if (response.statusCode === 200) {
                setNumberAlerts([]);
                setReloadAlerts(true);
                return;
            }
            if (response.statusCode === 400) {
                notification['error']({
                    message: response.description,
                });
                return;
            } else {
                let messageError = 'Ocurrió un error, Inténtelo otra vez';
                if (response.statusCode === 409) {
                    messageError = response.description;
                }

                notification['error']({
                    message: messageError,
                });
            }
            setReloadAlerts(true);
        }
    };
    return (
        <div className="menu-top">
            <div className="menu-top__left">
                <img
                    src={logoPlanB}
                    alt="logo_planB"
                    style={{ width: 60, marginLeft: 20 }}
                />
                <Button
                    icon={
                        menuCollapsed ? (
                            <MenuUnfoldOutlined />
                        ) : (
                            <MenuFoldOutlined />
                        )
                    }
                    type="link"
                    size="large"
                    onClick={() => setMenuCollapsed(!menuCollapsed)}
                ></Button>
            </div>

            <div className="menu-top__right">
                {[ROL_FINANCIAL, ROL_SUPPLIER].some((r) =>
                    person?.roles?.includes(r)
                ) && (
                    <Dropdown
                        trigger={['click']}
                        overlay={menuNotifications}
                        onClick={() => showNotifications()}
                        style={{ marginRight: 30 }}
                    >
                        <Badge count={numberAlerts} style={{ marginRight: 20 }}>
                            <NotificationFilled
                                style={{
                                    fontSize: 20,
                                    color: 'white',
                                    marginRight: 30,
                                }}
                            />
                        </Badge>
                    </Dropdown>
                )}

                <Dropdown overlay={menu}>
                    <a
                        href="javascript: void(0)"
                        className="ant-dropdown-link"
                        // onClick={(e) => e.preventDefault()
                        // }
                    >
                        <Avatar
                            src={profilePicture ? profilePicture : logoPlanB}
                            style={{ marginRight: 5 }}
                        />{' '}
                        {person.shortName}
                        <DownOutlined />
                    </a>
                </Dropdown>
            </div>
        </div>
    );
};

export default MenuTop;
