import LayoutAdmin from '../layout/LayoutAdmin';
import LayoutBasic from '../layout/LayoutBasic';

//Admin Pages
import AdminHome from '../pages/Admin';
import AdminBilling from '../pages/Admin/Billing';
import AdminPayment from '../pages/Admin/Payment';
import AdminClarifications from '../pages/Admin/Clarifications';
import AdminClarificationsDetail from '../pages/Admin/Clarifications/ClarificationsDetail';
import AdminAddClarifications from '../pages/Admin/Clarifications/AddClarificationPage';
import AdminAddClarificationPayment from '../pages/Admin/Clarifications/AddClarificationPaymentPage';
import AdminProfile from '../pages/Admin/Profile';
import AdminConfiguration from '../pages/Admin/Configuration';
import AdminCompanyData from '../pages/Admin/Configuration/CompanyData/CompanyData';
import AdminContact from '../pages/Admin/Configuration/ContactPage';
import AdminSeries from '../pages/Admin/Configuration/SeriesPage';
import Catalog from '../pages/Admin/Catalog';
import ClientsCatalog from '../pages/Admin/Catalog/Clients';
import UsersCatalog from '../pages/Admin/Catalog/Users';
import AlertsCatalog from '../pages/Admin/Catalog/Alert';
import BilllingDocument from '../pages/Admin/Billing/Documents';
import AdminCustomers from '../pages/Admin/SupplierPage';
import AdminFinancial from '../pages/Admin/SuppliersAdmin';
import AdminAddClarificationDocuments from '../pages/Admin/Clarifications/Documents/Documents';

//Pages
import Login from '../pages/Login';

//other
import Error404 from '../pages/Error404';
import PasswordRecovery from '../pages/PasswordRecovery/PasswordRecovery';
import ResetPassword from '../pages/ResetPassword/ResetPassword';
import {
    ROL_ADMIN,
    ROL_MANAGER,
    ROL_OPERACIONES,
    ROL_SUPPLIER,
    ROL_TODOS,
    ROL_FINANCIAL,
} from '../utils/constants';

const routes = [
    {
        path: '/login',
        component: LayoutBasic,
        exact: false,
        routes: [
            {
                path: '/login',
                component: Login,
                exact: true,
            },
            {
                path: '/login/recuperar-contraseña',
                component: PasswordRecovery,
                exact: true,
            },
            {
                component: Error404,
            },
        ],
    },
    {
        path: '/Account',
        component: LayoutBasic,
        exact: false,
        routes: [
            {
                path: '/Account/ResetPassword',
                component: ResetPassword,
                exact: true,
            },
        ],
    },
    {
        path: '/',
        component: LayoutAdmin,
        exact: false,
        routes: [
            {
                path: '/',
                component: AdminHome,
                roles: [
                    ROL_SUPPLIER,
                    ROL_MANAGER,
                    ROL_OPERACIONES,
                    ROL_FINANCIAL,
                ],
                exact: true,
            },
            {
                path: '/facturacion',
                component: AdminBilling,
                roles: [
                    ROL_SUPPLIER,
                    ROL_MANAGER,
                    ROL_OPERACIONES,
                    ROL_FINANCIAL,
                ],
                exact: true,
            },
            {
                path: '/facturacion/detalles/documentos/:id',
                roles: [
                    ROL_SUPPLIER,
                    ROL_MANAGER,
                    ROL_OPERACIONES,
                    ROL_FINANCIAL,
                ],
                component: BilllingDocument,
                exact: true,
            },
            {
                path: '/pagos',
                component: AdminPayment,
                roles: [
                    ROL_SUPPLIER,
                    ROL_MANAGER,
                    ROL_OPERACIONES,
                    ROL_FINANCIAL,
                ],
                exact: true,
            },
            {
                path: '/aclaraciones',
                component: AdminClarifications,
                roles: [ROL_SUPPLIER, ROL_MANAGER, ROL_OPERACIONES, ROL_FINANCIAL],
                exact: true,
            },
            {
                path: '/aclaraciones/detail',
                component: AdminClarificationsDetail,
                roles: [ROL_SUPPLIER, ROL_MANAGER, ROL_OPERACIONES, ROL_FINANCIAL],
                exact: true,
            },
            {
                path: '/aclaraciones/archivos/:idNote',
                component: AdminAddClarificationDocuments,
                roles: [ROL_SUPPLIER, ROL_MANAGER, ROL_OPERACIONES, ROL_FINANCIAL],
                exact: true,
            },
            {
                path: '/aclaraciones/detail/:idAclaracion',
                component: AdminClarificationsDetail,
                roles: [ROL_SUPPLIER, ROL_MANAGER, ROL_OPERACIONES, ROL_FINANCIAL],
                exact: true,
            },
            {
                path: '/aclaraciones/nueva/:idAclaracion',
                component: AdminAddClarifications,
                roles: [ROL_SUPPLIER, ROL_MANAGER, ROL_OPERACIONES, ROL_FINANCIAL],
                exact: true,
            },
            {
                path: '/aclaraciones/nueva/cp/:idAclaracion',
                component: AdminAddClarificationPayment,
                roles: [ROL_SUPPLIER, ROL_MANAGER, ROL_OPERACIONES, ROL_FINANCIAL],
                exact: true,
            },
            {
                path: '/perfil',
                component: AdminProfile,
                roles: ROL_TODOS,
                exact: true,
            },
            {
                path: '/configuracion',
                roles: [ROL_ADMIN],
                component: AdminConfiguration,
                exact: true,
            },
            {
                path: '/configuracion/compañia',
                roles: [ROL_ADMIN],
                component: AdminCompanyData,
                exact: true,
            },
            {
                path: '/configuracion/contacto',
                roles: [ROL_ADMIN],
                component: AdminContact,
                exact: true,
            },
            {
                path: '/configuracion/series',
                roles: [ROL_ADMIN],
                component: AdminSeries,
                exact: true,
            },
            {
                path: '/catalogo',
                roles: [ROL_ADMIN, ROL_FINANCIAL],
                component: Catalog,
                exact: true,
            },
            {
                path: '/catalogo/proveedores',
                roles: [ROL_ADMIN],
                component: ClientsCatalog,
                exact: true,
            },
            {
                path: '/catalogo/usuarios',
                roles: [ROL_ADMIN],
                component: UsersCatalog,
                exact: true,
            },
            {
                path: '/catalogo/alertas',
                roles: [ROL_ADMIN, ROL_FINANCIAL],
                component: AlertsCatalog,
                exact: true,
            },
            {
                path: '/proveedores',
                component: AdminCustomers,
                roles: [ROL_FINANCIAL, ROL_MANAGER],
                exact: true,
            },
            {
                path: '/catalogo/proveedores/:iduser',
                component: AdminFinancial,
                roles: [ROL_ADMIN],
                exact: true,
            },

            {
                component: Error404,
            },
        ],
    },
];

export default routes;
