import React, { useEffect, useState } from 'react';
import {
    Row,
    Col,
    Spin,
    Form,
    Input,
    Button,
    notification,
    Typography,
    Divider,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { addCommentApi, getCommentsApi } from '../../../../../api/documents';
import { getAccessTokenApi, logout } from '../../../../../api/auth';
import { ROL_MANAGER, ROL_OPERACIONES } from '../../../../../utils/constants';
import useUser from '../../../../../hooks/useUser';

const AddCommentForm = ({ setModalIsVisible, documentId }) => {
    const [loadingForm, setLoadingForm] = useState(false);
    const [reloadComments, setReloadComments] = useState(true);
    const [comments, setComments] = useState([]);
    const [form] = Form.useForm();
    const token = getAccessTokenApi();
    const { Title } = Typography;
    const { person } = useUser();

    useEffect(() => {
        if (reloadComments) {
            getCommentsApi(token, documentId)
                .then((response) => {
                    // console.log(response, 'response');
                    if (response) {
                        response.result.forEach((element, index) => {
                            element['key'] = index;
                        });
                        setComments(response.result);
                        setReloadComments(false);
                    }
                })
                .catch((err) => {});
        }
    }, []);

    const addCommentFunction = async (values) => {
        setLoadingForm(true);
        let data = values;

        // data['company'] = companyId;
        data['documentId'] = documentId;

        // console.log(values, 'values');
        const response = await addCommentApi(token, values);
        // console.log(response, 'response');
        if (response === undefined) {
            notification['error']({
                message: 'Ocurrió un error, Inténtelo más tarde',
            });
            setLoadingForm(false);
            return false;
        }
        if (response.statusCode === 401) {
            notification['error']({
                message: 'Usuario no autorizado.',
            });
            setTimeout(() => {
                logout();
                window.location.reload();
            }, 1500);
            return false;
        }
        if (response.statusCode === 200) {
            setModalIsVisible(false);
            notification['success']({
                message: 'Comentario agregado con éxito',
            });
            return;
        }
        if (response.statusCode === 400) {
            notification['error']({
                message: response.description,
            });
            setLoadingForm(false);
            return;
        } else {
            let messageError = 'Ocurrió un error, Inténtelo otra vez';
            if (response.statusCode === 409) {
                messageError = response.description;
            }
            if (response.statusCode === 400) {
                messageError = response.description;
            }
            notification['error']({
                message: messageError,
            });
        }
        setLoadingForm(false);
    };
    return (
        <Spin spinning={loadingForm} tip="Guardando...">
            <Form
                name="add-comment"
                layout="vertical"
                form={form}
                onFinish={addCommentFunction}
            >
                <div>
                    <Row xs={24} className="table-company">
                        <Col xs={20}>
                            {comments.length > 0 && (
                                <>
                                    <Title level={5}>Comentarios:</Title>
                                    {comments?.map((c) => (
                                        <>
                                            <p
                                                key={c.id}
                                                style={{
                                                    textTransform: 'capitalize',
                                                }}
                                            >
                                                -{c.text}
                                            </p>
                                        </>
                                    ))}
                                    <Divider />
                                </>
                            )}

                            {comments.length === 0 && (
                                <p style={{ textAlign: 'center' }}>
                                    No hay comentarios
                                </p>
                            )}

                            {[ROL_OPERACIONES, ROL_MANAGER].some((r) =>
                                person.roles.includes(r)
                            ) && (
                                <Form.Item
                                    label="Agregar Comentarios:"
                                    name="text"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Por favor ingrese comentario',
                                        },
                                    ]}
                                >
                                    <TextArea
                                        placeholder="Ingresa tu información"
                                        style={{ height: 150 }}
                                    />
                                </Form.Item>
                            )}

                            {[ROL_OPERACIONES, ROL_MANAGER].some((r) =>
                                person.roles.includes(r)
                            ) && (
                                <Row>
                                    <Col span={24}>
                                        <Form.Item>
                                            <Button
                                                htmlType="submit"
                                                className="btn-enviar"
                                                block
                                            >
                                                Guardar
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            )}
                        </Col>
                    </Row>
                </div>
            </Form>
        </Spin>
    );
};

export default AddCommentForm;
