import { basePath } from './config';

//Facturas y Notas de crédito
export function uploadInvoicesApi(token, data) {
    const url = `${basePath}/excel/suppliers/upload-invoices`;
    const formData = new FormData();

    if (data.file) {
        if (data.file.length > 0) {
        formData.append("file", data["file"][0].originFileObj);
        }
    }
    const params = {
        method: "POST",
        body: formData,
        headers: {
        Authorization: `Bearer ${token}`,
        },
    };

    return fetch(url, params)
        .then((response) => {
        return response.json();
        })
        .then((result) => {
        return result;
        })
        .catch((err) => {
        return err.message;
    });
}

//Aclaraciones
export function uploadNotesApi(token, data) {
    const url = `${basePath}/excel/suppliers/upload-notes`;
    const formData = new FormData();

    if (data.file) {
        if (data.file.length > 0) {
        formData.append("file", data["file"][0].originFileObj);
        }
    }
    const params = {
        method: "POST",
        body: formData,
        headers: {
        Authorization: `Bearer ${token}`,
        },
    };

    return fetch(url, params)
        .then((response) => {
        return response.json();
        })
        .then((result) => {
        return result;
        })
        .catch((err) => {
        return err.message;
        });
}

//Complementos de pago
export function uploadPaymentComplementsApi(token, data) {
    const url = `${basePath}/excel/suppliers/upload-payment-rec-complements`;
    const formData = new FormData();

    if (data.file) {
        if (data.file.length > 0) {
        formData.append("file", data["file"][0].originFileObj);
        }
    }
    const params = {
        method: "POST",
        body: formData,
        headers: {
        Authorization: `Bearer ${token}`,
        },
    };

    return fetch(url, params)
        .then((response) => {
        return response.json();
        })
        .then((result) => {
        return result;
        })
        .catch((err) => {
        return err.message;
        });
}