import React, { useEffect, useState } from 'react';
import { Button, Col, Layout, Row, Typography, notification } from 'antd';
import DocumentsTable from '../../../../components/Admin/Billling/Documents/DocumentsTable';
import GreetingsTop from '../../../../components/Admin/GreetingsTop/GreetingsTop';
import { useHistory, useParams } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { getDocumentsInfoApi } from '../../../../api/documents';
import { getAccessTokenApi } from '../../../../api/auth';
import { messageErrorGeneral } from '../../../../utils/general';

export default function Documents() {
    const { id } = useParams();
    const [infoOperation, setInfoOperation] = useState(null);
    const [laodingData, setLaodingData] = useState(false);
    const token = getAccessTokenApi();

    useEffect(() => {
        if (token && id) {
            const data = {
                reference: id,
                documentType: 'Evidence',
            };
            setLaodingData(true);
            getDocumentsInfoApi(token, data).then((response) => {
                // console.log(response, "get");
                if (response) {
                    if (response.statusCode === 409) {
                        notification['error']({
                            message: response.message,
                        });

                        setTimeout(() => {
                            window.location.href = '/operaciones';
                        }, 1500);
                    }

                    if (response.statusCode === 200) {
                        const result = response.result;
                        setInfoOperation(result[0]);
                    }
                } else {
                    notification['error']({
                        message: messageErrorGeneral(),
                    });

                    setTimeout(() => {
                        window.location.href = '/operaciones';
                    }, 1500);
                }
                setLaodingData(false);
            });
        }
    }, [token, id]);

    return (
        <Layout>
            <GreetingsTop />

            <HeaderTop idBilling={id} />

            <div className="billing__container">
                <DocumentsTable idBilling={id} />
            </div>
        </Layout>
    );
}
function HeaderTop({idBilling}) {
    const { Title, Paragraph } = Typography;
    let history = useHistory();
    return (
        <div className="billing__container">
            <Row xs={24} className="header-top">
                <Col xs={20} md={10} className="header-top__left">
                    <Row>
                        <Title level={3} style={{ marginRight: 20 }}>
                            Documentos
                        </Title>
                    </Row>
                </Col>
                <Col xs={10} md={12} className="header-top__right">
                    <Button type="link" onClick={() => history.goBack()}>
                        <ArrowLeftOutlined /> Atrás
                    </Button>
                </Col>
                <Col xs={24}>
                    <Paragraph strong>Referencia o Factura: {idBilling}</Paragraph>
                </Col>
            </Row>
        </div>
    );
}
