import { basePath } from './config';

export function getSuppliersFinancialApi(token, id) {
    const url = `${basePath}/financial/${id}/suppliers`;
    const params = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {});
}
export function deleteSupplierFinancialApi(token, idFinancial, idCustomer) {
    const url = `${basePath}/financial/${idFinancial}/supplier/${idCustomer}/remove-from-list`;
    const params = {
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {});
}
