import React, { useEffect, useState } from 'react';
import { Col, Layout, Row, Typography, notification, Space } from 'antd';
import GreetingsTop from '../../../components/Admin/GreetingsTop/GreetingsTop';
import BillingFilter from '../../../components/Admin/Billling/BillingFilters/BillingFilter';
import useUser from '../../../hooks/useUser';
import moment from 'moment';
import DatePickerOptions from '../../../components/General/DatePickerOptions/DatePickerOptions';
import useStatusFilter from '../../../hooks/useStatusFilter';
import BillingTable from '../../../components/Admin/Billling/BillingTable';
import { ROL_LAYOUT, ROL_SUPPLIER } from '../../../utils/constants';
import { getAccessTokenApi, logout } from '../../../api/auth';
import { getAllApiInvoices } from '../../../api/billing';
import { messageError } from '../../../utils/general';
import useDataGeneral from '../../../hooks/useDataGeneral';

import './Billing.scss';

export default function Billing() {
    const [reloadBills, setReloadBills] = useState(true);
    const [bills, setBills] = useState([]);
    const token = getAccessTokenApi();
    const { statusData, reset } = useDataGeneral();
    const [dateRange, setDateRange] = useState(null);

    const date = [moment().subtract(7, 'days'), moment()];
    const { statusFilter } = useStatusFilter();
    const [dateReceive, setDateReceive] = useState([]);

    useEffect(() => {
        if (reloadBills) {
            filterDate(date, 'Documento');
            setReloadBills(false);
        }
    }, []);

    useEffect(() => {
        //filtar dependiendo el estatus de los amount
        const statusReceive = statusFilter.type;
        if (statusReceive != '') {
            // 'Vigente', 'Vencido', //TODO -> Status
            let dayToSearch = [moment().subtract(7, 'days'), moment()];
            if (statusFilter.dateStart != '' && statusFilter.dateEnd != '') {
                dayToSearch = [
                    moment(statusFilter.dateStart),
                    moment(statusFilter.dateEnd),
                ];
            }
            setDateReceive(dayToSearch);
            filterDate(dayToSearch, 'Documento', statusFilter.type);
        }
    }, []);

    useEffect(() => {
        if (statusData.reloadTable) {
            // console.log(statusData, 'statusData');
            filterDate(date, 'Documento');
            setReloadBills(false);
            reset();
        }
    }, [statusData]);

    function filterDate(date, dateString, status) {
        setReloadBills(true);
        // console.log(dateString, 'datestring');
        if (date) {
            // setFilteredInfo({});
            // setSortedInfo({});
            let date2 = {
                startDate: moment
                    .utc(date[0].hour(0).minute(0))
                    .format('YYYY-MM-DD HH:mm'),
                endDate: moment
                    .utc(date[1].hour(23).minute(59))
                    .format('YYYY-MM-DD HH:mm'),
            };
            let date3 = {
                startDate: moment(date[0]).format('DD-MM-YYYY'),
                endDate: moment(date[1]).format('DD-MM-YYYY'),
            };
            setDateRange(date3);

            let filters = '';
            if (dateString === 'Documento') {
                filters = `?DocStartDate=${date2.startDate}&DocEndDate=${date2.endDate}${status !== "" ? status === "Total" ? '&status=Vencido&status=Vigente' :`&status=${status}` : ''}`;
            } else if (dateString === 'Recepcion') {
                filters = `?IssueStartDate=${date2.startDate}&IssueEndDate=${date2.endDate}`;
            }

            getAllApiInvoices(token, filters).then((response) => {
                // console.log(response, 'bills');
                if (response === undefined) {
                    const msg = messageError();

                    notification['error']({
                        message: msg,
                    });
                    return;
                }
                if (response?.statusCode === 401) {
                    notification['error']({
                        message: 'Usuario no autorizado.',
                    });
                    setTimeout(() => {
                        logout();
                        window.location.reload();
                    }, 1000);
                    return;
                } else if (response?.statusCode === 200) {

                    response.result.forEach((element, index) => {
                        element['key'] = index;
                    });
                    setBills(response.result);
                    setReloadBills(false);
                }
            });
        }
    }

    return (
        <Layout>
            <GreetingsTop />

            <HeaderTop filterDate={filterDate} dateReceive={dateReceive} />

            <div className="billing__container">
                <BillingTable
                    bills={bills}
                    reloadBills={reloadBills}
                    dateRange={dateRange}
                />
            </div>
        </Layout>
    );
}

function HeaderTop({ filterDate, dateReceive }) {
    const { Title } = Typography;
    const { person } = useUser();

    return (
        <div className="billing__container">
            <Row className="header-top">
                <Col xs={24} md={12} className="header-top__left">
                    <Title level={3}>Facturación</Title>
                </Col>
                
                <Col
                    xs={20}
                    md={12}
                    style={{
                        display: 'grid',
                        alignItems: 'center',
                        justifyContent: 'end',
                    }}
                >
                    <Row xs={24} md={12} style={{ marginBottom: 10 }}>
                        <p xs={24} style={{ marginRight: 20 }}>
                            Fecha de Documento:
                        </p>
                        <DatePickerOptions
                            xs={24}
                            filterDate={filterDate}
                            typeDoc={'Documento'}
                            dateDisplay={dateReceive}
                        />
                    </Row>
                    {/* <Row xs={10} md={12}>
                        <p style={{ marginRight: 26 }}>Fecha de Recepción:</p>
                        <DatePickerOptions
                            filterDate={filterDate}
                            typeDoc={'Recepcion'}
                        />
                    </Row> */}
                </Col>
                {[ROL_SUPPLIER, ROL_LAYOUT].some((r) => person.roles.includes(r)) && (
                    <Col
                        xs={24}
                        md={24}
                        lg={24}
                        className="header-top__right"
                        // style={{ marginTop: 20 }}
                    >
                        <div>
                            <BillingFilter />
                        </div>
                    </Col>
                )}
            </Row>
        </div>
    );
}
