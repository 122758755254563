import React, { useState } from 'react';
import { Row, Col, Menu, Dropdown, Button, Typography } from 'antd';
// import {
//     LineChart,
//     Line,
//     XAxis,
//     YAxis,
//     CartesianGrid,
//     Tooltip,
//     Legend,
//     ResponsiveContainer,
// } from 'recharts';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from 'recharts';

import './ChartAmount.scss';
import DatePickerOptions from '../../General/DatePickerOptions/DatePickerOptions';
import { formatMoney } from '../../../utils/general';

export default function ChartAmount({ summary, filterDate, monthDate }) {
    // console.log(summary, 'summary');
    const [monthName, setMonthName] = useState(null);
    const { Title } = Typography;

    switch (monthDate) {
        case 1:
            setMonthName('ENERO');
            break;

        case 2:
            setMonthName('FEBRERO');
            break;

        case 3:
            setMonthName('MARZO');
            break;
        case 4:
            setMonthName('ABRIL');
            break;
        case 5:
            setMonthName('MAYO');
            break;
        case 6:
            setMonthName('JUNIO');
            break;
        case 7:
            setMonthName('JULIO');
            break;
        case 8:
            setMonthName('AGOSTO');
            break;
        case 9:
            setMonthName('SEPTIEMBRE');
            break;
        case 10:
            setMonthName('OCTUBRE');
            break;
        case 11:
            setMonthName('NOVIEMBRE');
            break;
        case 12:
            setMonthName('DICIEMBRE');
            break;
        default:
            break;
    }

    const data = [
        {
            name: monthName,
            Facturacion: summary[0]?.total,
            Notas: summary[1]?.total,
            amt: 2400,
        },
    ];

    return (
        <>
            <Row className="chart-amount">
                <Col span={24} className="chart-amount__title">
                    <Title level={3}>
                        RESUMEN DE FACTURACIÓN
                    </Title>
                </Col>
    
                <Col span={24} className="chart-amount__dropdown">
                    <DatePickerOptions filterDate={filterDate} />
                </Col>
                <Col span={24}>
                    <ResponsiveContainer  
                        width="100%" 
                        height={450}
                    >
                        <BarChart
                            data={data}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            {/* <XAxis
                                dataKey="month"
                                padding={{ left: 30, right: 30 }}
                                tick={<CustomizedAxisTick />}
                            />
                            <YAxis
                                padding={{ top: 30 }}
                                tickFormatter={(value) => {
                                    return value;
                                }}
                            /> */}

                            <XAxis dataKey="name" />
                            <YAxis 
                                width={200}
                                padding={{ left: 30, }}
                                tickFormatter={(value) => {
                                    return `$ ${formatMoney(value)}`;
                                }} 
                            />
                            <Legend />

                            {/* <YAxis yAxisId="left" padding={{ top: 30 }} tickFormatter={(value)=>{return formatMoney(value)}} label={{ value: "Ventas", angle: -90, position: 'insideLeft', offset: -5}} /> */}
                            {/* <YAxis yAxisId="right" orientation="right" padding={{ top: 30 }} tickFormatter={(value)=>{return formatMoney(value)}} label={{ value: "Canceladas", angle: 90, position: 'insideRight', offset: -20 }} />   */}

                            <Tooltip
                                formatter={(value, name, props) => {
                                    return '$ ' + formatMoney(value);
                                }}
                            />
                            <Bar dataKey="Facturacion" name="Facturación" fill="#88cb88" />
                            <Bar dataKey="Notas" name="Notas de crédito" fill="#cf3b3f" />
                            {/* <Tooltip formatter={(value, name, props)=>{return '$ '+formatMoney(value)}} /> */}
                            {/* <Bar
                                type="monotone"
                                dataKey="uv"
                                stroke="#82ca9d"
                                activeDot={{ r: 8 }}
                                strokeWidth={1.5}
                                label={<CustomizedLabel />}
                            />
                            <Bar
                                type="monotone"
                                dataKey="pv"
                                stroke="#d9363e"
                                strokeWidth={1}
                            /> */}

                            {/* <Line yAxisId="left" type="monotone" dataKey="sales" stroke="#82ca9d" activeDot={{ r: 8 }} strokeWidth={1.5} label={<CustomizedLabel />}/>
    <Line yAxisId="right" type="monotone" dataKey="cancelled" stroke="#d9363e" strokeWidth={1} /> */}
                        </BarChart>
                    </ResponsiveContainer>
                </Col>
            </Row>
        </>
    );
}

function CustomizedAxisTick(props) {
    const { x, y, payload } = props;

    return (
        <g transform={`translate(${x},${y})`}>
            <text
                x={0}
                y={0}
                dy={16}
                textAnchor="end"
                fill="#666"
                transform="rotate(-35)"
            >
                {payload.value}
            </text>
        </g>
    );
}

function CustomizedLabel(props) {
    const { x, y, stroke, value } = props;

    return (
        <text
            x={x}
            y={y}
            dy={-4}
            fill={stroke}
            fontSize={12}
            textAnchor="middle"
        >
            {value}
        </text>
    );
}
